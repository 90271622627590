<template>
  <div
    class="date-range-picker md:fit-content"
    :class="{
      selected,
      'in-range': ranges.inRange,
      'sm-in-range': ranges.sameStartMonth,
      'em-in-range': ranges.sameEndMonth,
      'select-all': ranges.selectAll,
    }"
  >
    <div class="mx-4 md:mt-4 grid grid-cols-13 items-center">
      <v-text-field
        v-model="from"
        :disabled="disabled || disableManualInputs"
        class="col-span-6"
        variant="outlined"
        rounded="lg"
        density="compact"
        hide-details
      />
      <span class="flex justify-center">
        <v-icon>arrow_right_alt</v-icon>
      </span>
      <v-text-field
        v-model="to"
        :disabled="disabled || disableManualInputs"
        class="col-span-6"
        variant="outlined"
        rounded="lg"
        density="compact"
        hide-details
      />
    </div>
    <v-date-picker
      v-model="date"
      v-model:month="cursor.month"
      v-model:year="cursor.year"
      :min="min"
      :max="max"
      :disabled="disabled"
      multiple
      hide-header
    />
  </div>
</template>

<script lang="ts">
import dayjs from "dayjs";
import { get, last, set } from "lodash";
import { defineComponent } from "vue";

export default defineComponent({
  name: "DateRangePicker",
  props: {
    modelValue: { type: Array as PropType<Date[]> },
    start: { type: Object as PropType<Date> },
    end: { type: Object as PropType<Date> },
    disabled: { type: Boolean, default: false },
    disableManualInputs: { type: Boolean, default: false },
    min: {},
    max: {},
  },
  setup(props, ctx) {
    const date$0 = ref<Date[]>();

    const model = computed({
      set(value) {
        ctx.emit("update:modelValue", toRaw(value));
      },
      get() {
        return toRaw(props.modelValue);
      },
    });

    const getModelValue = (): Date[] => {
      return props.modelValue ? toRaw(props.modelValue) : [];
    };

    const cursor = ref({
      month: new Date().getMonth(),
      year: new Date().getFullYear(),
    });

    const date = computed({
      set(value: Date[]) {
        let from: Date | undefined;
        let to: Date | undefined;

        if (value.length == 2) {
          from = value[0];
          to = value[1];
        } else {
          from = last(value);
        }

        if (from) {
          if (to) {
            date$0.value = [from, to];
            model.value = [from, to];
          } else {
            date$0.value = [from];
            model.value = [from];
          }
        } else {
          date$0.value = [];
          model.value = [];
        }
      },
      get() {
        if (date$0.value) {
          return date$0.value;
        }
        if (model.value) {
          return [...getModelValue()];
        }
        return [];
        // return date$0.value || model.value || [];
      },
    });

    const makeTextInputProxy = (index: number) => {
      let ref: string | undefined;
      return computed({
        set(value: string | undefined) {
          const range = getModelValue();
          if (value && value.length >= 10) {
            const v = value ? dayjs(value, "YYYY-MM-DD").toDate() : undefined;
            ref = v ? undefined : value;
            set(range, index, v);
            date$0.value = range;
            model.value = range;
          } else {
            ref = value;
          }
        },
        get(): string | undefined {
          if (!ref) {
            const v = get(model.value, index);
            if (v) {
              return dayjs(v).format("YYYY-MM-DD");
            }
          }
          return ref;
        },
      });
    };

    const selected = computed(() => date.value?.length > 1);
    const from = makeTextInputProxy(0);
    const to = makeTextInputProxy(1);

    const parseDate = (date: string) => {
      const regex = /^(\d{4})-(\d{2})-\d{2}$/;
      const match = date?.match(regex);
      if (match) {
        return {
          year: Number(match[1]),
          month: Number(match[2]),
        };
      }
    };

    const ranges = computed(() => {
      let sameStartMonth = false;
      let sameEndMonth = false;
      const s = { m: 0, y: 0 };
      const e = { m: 0, y: 0 };
      const year = cursor.value.year;
      const month = cursor.value.month + 1;

      if (from.value) {
        const p = parseDate(from.value);
        s.y = p?.year || 0;
        s.m = p?.month || 0;
        sameStartMonth = year == s.y && month == s.m;
      }
      if (to.value) {
        const p = parseDate(to.value);
        e.y = p?.year || 0;
        e.m = p?.month || 0;
        sameEndMonth = year == e.y && month == e.m;
      }

      return {
        sameStartMonth,
        sameEndMonth,
        inRange: sameStartMonth && sameEndMonth,
        selectAll: s.y <= year && s.m < month && e.y >= year && e.m > month,
      };
    });

    return {
      cursor,
      date,
      from,
      to,
      selected,
      ranges,
    };
  },
});
</script>

<style lang="scss">
.date-range-picker {
  --highlight-color: rgba(150, 150, 150, 0.12);

  &.selected {
    --highlight-color: rgba(150, 150, 150, 0.3);
  }

  .v-date-picker-month__day {
    &.v-date-picker-month__day--selected::before {
      position: absolute;
      background: var(--highlight-color);
      top: 2px;
      left: 2px;
      bottom: 2px;
      height: calc(100% - 4px);
      content: " ";

      right: 4px;
      width: calc(100% - 4px);
      border-radius: 0 10px 10px 0;
    }

    &.v-date-picker-month__day--selected:not(
        .v-date-picker-month__day--selected
          ~ .v-date-picker-month__day--selected
      )::before {
      position: absolute;
      right: 2px;
      width: calc(100% + 4px);
      border-radius: 10px 0 0 10px;
    }
  }

  // highlight dates between
  &.in-range:not(.selected)
    .v-date-picker-month__day.v-date-picker-month__day--selected
    ~ .v-date-picker-month__day[data-v-date]:has(
      ~ .v-date-picker-month__day:hover
    ),
  .v-date-picker-month__day.v-date-picker-month__day--selected
    ~ .v-date-picker-month__day[data-v-date]:has(
      ~ .v-date-picker-month__day--selected
    ) {
    position: relative;
    &,
    & > button {
      background: transparent;
    }
    &::before {
      position: absolute;
      left: 2px;
      top: 2px;
      right: 2px;
      bottom: 2px;
      height: calc(100% - 4px);
      width: calc(100% + 4px);
      background: var(--highlight-color);
      border-radius: 0;
      content: " ";
    }
  }
}
</style>
