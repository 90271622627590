<template>
  <v-menu
    v-model="opened"
    @update:modelValue="onOpened"
    :close-on-content-click="false"
    location="bottom"
    transition="slide-y-transition"
    offset="15"
  >
    <template v-slot:activator="{ props }">
      <slot name="activator" v-bind="{ display, props }"></slot>
    </template>
    <v-card>
      <div class="md:flex">
        <div class="hidden md:inline-block w-200px">
          <v-item-group v-model="selectionValue" :disabled="disabled">
            <v-list :lines="false" density="compact" nav>
              <div v-for="item in shortcutsItems" :key="item.value">
                <v-item v-slot="{ isSelected, toggle }" :value="item.value">
                  <v-list-item
                    @click="toggle"
                    :key="item.value"
                    :title="item.title"
                    :active="isSelected"
                  >
                    <template #append>
                      <v-icon v-if="isSelected" size="20">check</v-icon>
                    </template>
                  </v-list-item>
                </v-item>
              </div>
            </v-list>
          </v-item-group>
        </div>
        <div class="hidden md:flex">
          <v-divider vertical />
        </div>
        <div class="md:hidden pa-4">
          <v-select
            v-model="selectionValue"
            :disabled="disabled"
            :items="shortcutsItems"
            variant="outlined"
            rounded="lg"
            density="compact"
            hide-details
          />
        </div>
        <div>
          <DateRangePicker
            v-model="periodValue"
            :disabled="disabled"
            :disable-manual-inputs="disableInputs"
          />
          <v-divider />
          <div class="flex justify-end py-2 px-4">
            <v-btn @click="close">Close</v-btn>
          </div>
        </div>
      </div>
    </v-card>
  </v-menu>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import dayjs from "dayjs";
import { useLocalStorage } from "@vueuse/core";

const shortcuts = [
  { title: "Today", value: "day" },
  { title: "Last 7 days", value: "custom:7:days" },
  { title: "Last 4 weeks", value: "custom:4:weeks" },
  { title: "Last 12 months", value: "custom:12:months" },
  { title: "Month to date", value: "month" },
  { title: "Quarter to date", value: "quarter" },
  { title: "Year to date", value: "year" },
  { title: "All time", value: "all-time" },
];

export default defineComponent({
  name: "MetricsPeriodPicker",
  props: {
    storeKey: { type: String, default: "default" },
    selection: { type: String as PropType<string> },
    period: { type: Array as PropType<Date[]> },
    disabled: { type: Boolean, default: false },
  },
  setup(props, ctx) {
    const opened = ref(false);

    const state = useLocalStorage(`period-picker-${props.storeKey}`, {
      selection: "day",
      period: [] as Date[],
    });

    const getPeriodFromSelection = (v: string) => {
      if (v?.startsWith("custom:")) {
        const [_, days, unit] = v.split(":");
        const from = now().subtract(parseInt(days), unit as any);
        return [from.toDate(), new Date()];
      } else if (v == "custom") {
        //
      } else {
        if (v !== "all-time" && v !== "custom") {
          const from = now().startOf(v as any);
          return [from.toDate(), new Date()];
        } else {
          //
        }
      }
      return [];
    };

    const selectionValue = computed({
      set(v: string) {
        state.value = {
          selection: v,
          period: [],
        };
      },
      get(): string {
        return state.value.selection;
      },
    });

    const periodValue = computed({
      set(v: Date[]) {
        state.value = {
          period: v,
          selection: "custom",
        };
      },
      get() {
        if (state.value.period?.length) {
          return state.value.period;
        }
        return getPeriodFromSelection(state.value.selection);
      },
    });

    watchEffect(() => {
      // ctx.emit("update:selection", toRaw(selectionValue.value));
      // ctx.emit("update:period", toRaw(periodValue.value));
      const s = toRaw(state.value);
      const period = s.period.length
        ? s.period
        : getPeriodFromSelection(s.selection);

      ctx.emit("update:selection", s.selection);
      ctx.emit("update:period", period);
    });

    const display = computed(() => {
      if (selectionValue.value?.startsWith("custom:")) {
        const [_, days, unit] = selectionValue.value.split(":");
        const from = now().subtract(parseInt(days), unit as any);
        const to = dayjs();
        const format = from.year() == to.year() ? "D MMM" : "D MMM YYYY";
        return from.format(format) + "—" + to.format(format);
      } else if (state.value.selection == "custom") {
        if (state.value.period?.length < 2) {
          return "Custom";
        }
        const [from, to] = state.value.period;
        return dayjs(from).format("D MMM") + "—" + dayjs(to).format("D MMM");
      } else {
        const s = findWhere(shortcuts, { value: selectionValue.value });
        return s?.title;
      }
    });

    const disableInputs = computed(() => {
      return (
        selectionValue.value == "day" || selectionValue.value == "all-time"
      );
    });

    const onOpened = () => {
      // if (props.selection) {
      //   selection.value = props.selection;
      // }
    };

    const close = () => {
      opened.value = false;
    };

    const shortcutsItems = computed(() => {
      if (selectionValue.value === "custom") {
        return [...shortcuts, { title: "Custom", value: "custom" }];
      }
      return shortcuts;
    });

    return {
      display,
      disableInputs,
      shortcutsItems,
      selectionValue,
      opened,
      periodValue,
      onOpened,
      close,
    };
  },
});
</script>
